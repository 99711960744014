























































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      item: {},
      customer: {},
      packages: [],
      print: false,
      policy_money: 0,
    };
  },
  computed: {
    debt() {
      return this.item.total_money + this.item.fee_vn - this.item.paid;
    },
    discount() {
      if (this.item.discount_percentage) {
        return (this.item.fee_transport * this.item.discount_percentage) / 100;
      }
      return this.item.discount || 0;
    },
  },
  watch: {
    packages: {
      handler(val) {
        this.policy_money = 0;
        if (Array.isArray(val) && val.length) {
          val.forEach((item) => {
            this.policy_money += item.policy_money;
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.print = this.$route.query.print;

      if (this.$route.params.id === "new") {
        const ids = this.$route.query.ids.split(",");
        this.item = {
          package_ids: ids,
        };
        this.item.customer_id = this.$route.query.customer_id;
        this.item.truck_id = this.$route.query.truck_id;
        this.item.sale_user_id = this.$route.query.sale_user_id;
        if (this.item.customer_id) {
          this.item.customer = await apiClient.customerGet(this.item.customer_id);
          if (this.item.customer) {
            this.item.discount_percentage = this.item.customer.discount_percentage;
          }
          if (!this.item.discount_percentage) {
            this.item.discount_percentage = 0;
          }
        }
        this.item.accountantUser = userManager.getUserInfo();
        const { items } = await apiClient.packageGetAll({
          rawOptions: {
            limit: -1,
          },
          filters: [
            {
              key: "id",
              operator: "in",
              value: ids,
            },
          ],
        });
        this.packages = items;
        const keys = [
          "weight",
          "volume",
          "count",
          "debt",
          "fee_service",
          "fee_transport",
          "fee_trust",
          "interior_cost",
          "fee_other",
          "policy_money",
          "export_bill_vat",
          "export_import_tax",
          "fee_vn",
          "fee_total",
          "profit",
        ];
        for (const key of keys) {
          this.item[key] = 0;
        }
        for (const pkg of this.packages) {
          for (const key of keys) {
            if (pkg[key]) {
              this.item[key] += pkg[key];
            }
          }
        }
        this.item.interior_cost = this.item.interior_cost * 3500;
        this.item.export_vat = this.item.export_bill_vat;
        this.recalculateTotalMoney();
      } else {
        this.item = await apiClient.liquidationSlipGet(this.$route.params.id);
        const { items } = await apiClient.packageGetAll({
          rawOptions: {
            limit: -1,
          },
          filters: [
            {
              key: "liquidation_slip_id",
              operator: "equal_to",
              value: this.$route.params.id,
            },
          ],
        });
        this.packages = items;
        if (!this.item.fee_total) {
          const keys = ["fee_total"];
          for (const key of keys) {
            this.item[key] = 0;
          }
          for (const pkg of this.packages) {
            for (const key of keys) {
              if (pkg[key]) {
                this.item[key] += pkg[key];
              }
            }
          }
        }
        this.recalculateTotalMoney();
      }
      this.customer = this.item.customer;

      this.$nextTick(() => {
        if (this.print) {
          window.print();
        }
      }, 2000);
    },
    recalculateTotalMoney() {
      this.item.total_money = this.item.fee_total + this.item.debt;
      this.item.total_money +=
        (this.item.fee_trust || 0) - this.packages.reduce((total, pkg) => total + (pkg.fee_trust || 0), 0);
    },
    async update() {
      let result;
      if (!this.item.id) {
        this.recalculateTotalMoney();
        result = await apiClient.liquidationSlipCreate(this.item);
        if (result) {
          this.$router.push(`/app/liquidation_slips/${result.id}`);
        }
      } else {
        this.recalculateTotalMoney();
        result = await apiClient.liquidationSlipUpdate(this.item);
        if (result) {
          location.reload();
        }
      }
    },
    download() {
      window.open(`#/ext/liquidation_slips/${this.item.id}?print=true`, "_blank");
    },
    async approve() {
      if (await apiClient.liquidationSlipApprove(this.item)) {
        location.reload();
      }
    },
    willShowCol(name) {
      return !this.print || this.item[name];
    },
    numCol() {
      let count = 16;
      if (this.print) {
        const keys = [
          "weight",
          "volume",
          "count",
          "debt",
          "fee_service",
          "fee_transport",
          "fee_trust",
          "interior_cost",
          "fee_other",
          "export_vat",
          "export_import_tax",
          "fee_vn",
        ];
        for (const key of keys) {
          if (!this.item[key]) {
            count--;
          }
        }
      }
      return count;
    },
    isArray(obj) {
      return obj instanceof Array;
    },
  },
  created() {
    this.initialize();
  },
});
